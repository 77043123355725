import React, { FC } from 'react';
import styled from 'styled-components';
import { MicrositeData } from 'utils/types';
import * as ga from 'lib/ga';
import { capitalize, get, isEmpty, some } from 'lodash';
import { PARTNER } from 'constant/partner';
import {
  SOCIAL_MEDIA_ICONS,
  SOCIAL_MEDIA_KEY_MAPPED,
  SOCIAL_MEDIA_NAME,
  SOCIAL_MEDIA_PLATFORM,
} from 'constant/constants';
import { TRACKING } from 'constant/analytics';

type IconWithTextProps = {
  $fill: boolean;
  $fontColor: string;
};

const IconWithText = styled.a<IconWithTextProps>`
  text-align: center;
  color: var(--white);
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  transition: transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0s;

  ${({ $fill, $fontColor }) =>
    $fill &&
    `
    & > svg path {
      fill: ${$fontColor};
    }
  `}

  ${({ $fill, $fontColor }) =>
    !$fill &&
    `
    & > svg path {
      stroke: ${$fontColor};
    }
  `}

  :hover {
    transform: scale(1.075);
  }
`;

const Container = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .footer {
    padding: 20px;

    .footer-title {
      text-align: center;
      margin-bottom: 30px;
      color: var(--white);
    }
  }
`;

type SocialMediaLinksProps = {
  micrositeData: MicrositeData;
  showTitle?: boolean;
  brandColor?: string;
};

export const SocialMediaLinks: FC<SocialMediaLinksProps> = ({ micrositeData, showTitle, brandColor }) => {
  const { thirdPartyLinks, name } = micrositeData || {};
  const fontColor = get(micrositeData, 'micrositeConfigDetails.micrositeTheme.fontColor', 'black');
  const fontType = get(micrositeData, 'micrositeConfigDetails.micrositeTheme.fontType', 'Gilroy-Regular');

  return (
    <>
      {showTitle && !isEmpty(micrositeData?.thirdPartyLinks) && (
        <div className="body-content follow-text w-full" style={{ color: brandColor }}>
          Follow {name}
        </div>
      )}
      <Container style={{ color: brandColor, fontFamily: fontType }}>
        {thirdPartyLinks?.map((type: { link: any; category: any }, key: React.Key | null | undefined) => {
          return (
            <IconWithText
              $fontColor={brandColor || fontColor}
              key={key}
              href={type.link}
              target="_blank"
              $fill={some(
                [
                  PARTNER.GOOGLE,
                  SOCIAL_MEDIA_PLATFORM.MESSENGER,
                  SOCIAL_MEDIA_PLATFORM.WHATSAPP,
                  SOCIAL_MEDIA_PLATFORM.TWITTER,
                  SOCIAL_MEDIA_PLATFORM.SNAPCHAT,
                  SOCIAL_MEDIA_PLATFORM.YOUTUBE,
                  SOCIAL_MEDIA_PLATFORM.YELP,
                  SOCIAL_MEDIA_PLATFORM.LINE,
                  SOCIAL_MEDIA_PLATFORM.SPOTIFY,
                  SOCIAL_MEDIA_NAME[SOCIAL_MEDIA_PLATFORM.TRIP_ADVISOR],
                ],
                (partner) => capitalize(partner) === capitalize(type.category),
              )}
              onClick={() => {
                ga.event(TRACKING.HOME.PARTNER[SOCIAL_MEDIA_KEY_MAPPED[type?.category as string]]);
              }}
            >
              {SOCIAL_MEDIA_ICONS[type.category as string]}
            </IconWithText>
          );
        })}
      </Container>
    </>
  );
};

export default SocialMediaLinks;
